import styled from "styled-components";
import { useTranslation } from "react-i18next";

function URLShare() {
  const url = "https://couple-attachment.com";
  const { t } = useTranslation();
  return (
    <URLShareDiv
      onClick={() => {
        navigator.clipboard.writeText(url);
        alert(t('body.share.linkCopy'));
      }}
    >
      <SmallCircleDiv>URL</SmallCircleDiv>
      <URLShareText>{ t('body.share.url') }</URLShareText>
    </URLShareDiv>
  );
}
const URLShareDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const SmallCircleDiv = styled.div`
  position: relative;
  width: 43px;
  height: 43px;
  background: #ffcdb1;
  border-radius: 50%;
  text-align: center;
  line-height: 43px;
  font-weight: 600;
  color: white;
  margin-bottom: 11px;
`;

const URLShareText = styled.div`
  text-align: center;
  color: #6C625C;
  font: 600 11px MaruBuri, sans-serif;
  word-wrap: break-word;
`;

export default URLShare;

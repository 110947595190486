import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import QuestionItem from "../../components/QuestionItem";
import questionList from "../../data/questionList";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import history from "../../components/History";
import axios from "axios";
import { useTranslation } from "react-i18next";

function Survey() {
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    history.listen((event) => {});
    dispatch({ type: "INIT", payload: null });
  }, [history, location]);

  return (
    <>
      <Questionnarie></Questionnarie>
      <ProgressBarOrSubmit></ProgressBarOrSubmit>
    </>
  );
}

function Questionnarie() {
  const questions = useSelector((state) => state.questionState.lis);
  const scrollRef = useRef();

  useEffect(() => {
    scrollToBottom();
  }, [questions]);

  const scrollToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  return (
    <Questions ref={scrollRef}>
      {questions.map((question) => (
        <QuestionItem key={question.id} question={question} />
      ))}
    </Questions>
  );
}

function ProgressBarOrSubmit() {
  const lastId = useSelector((state) => state.questionState.lastId);
  const answerScores = useSelector((state) => state.answerScores);
  const progressPercentile = Math.round(
    ((lastId - 1) / questionList.length) * 100
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  async function sendSurveyResult(
    type,
    answer_scores,
    avg_odd_score,
    avg_even_score
  ) {
    try {
      const response = await axios.post(
        "https://couple-attachment.com/api/survey-results",
        {
          type: type,
          answer_scores: answer_scores,
          avg_odd_score: avg_odd_score,
          avg_even_score: avg_even_score,
        }
      );
    } catch (error) {
    }
  }

  const submitAnswers = () => {
    const avgOddScore =
      (answerScores
        .filter((value, index) => index % 2 === 1)
        .reduce((a, b) => a + b, 0) /
        questionList.length) * 2;
    const avgEvenScore =
      (answerScores
        .filter((value, index) => index % 2 === 0)
        .reduce((a, b) => a + b, 0) /
        questionList.length) * 2;

    if (avgOddScore < 2.33) {
      if (avgEvenScore < 2.61) {
        sendSurveyResult(0, answerScores, avgOddScore, avgEvenScore);
        navigate(
          "/result?type=0&avg_odd_score=" +
            avgOddScore +
            "&avg_even_score=" +
            avgEvenScore
        );
      } else {
        sendSurveyResult(1, answerScores, avgOddScore, avgEvenScore);
        navigate(
          "/result?type=1&avg_odd_score=" +
            avgOddScore +
            "&avg_even_score=" +
            avgEvenScore
        );
      }
    } else {
      if (avgEvenScore < 2.61) {
        sendSurveyResult(2, answerScores, avgOddScore, avgEvenScore);
        navigate(
          "/result?type=2&avg_odd_score=" +
            avgOddScore +
            "&avg_even_score=" +
            avgEvenScore
        );
      } else {
        sendSurveyResult(3, answerScores, avgOddScore, avgEvenScore);
        navigate(
          "/result?type=3&avg_odd_score=" +
            avgOddScore +
            "&avg_even_score=" +
            avgEvenScore
        );
      }
    }
  };

  if (progressPercentile < 100) {
    return (
      <ProgressDiv>
        <ProgressText>
          <ProgressTitle>{ t('body.survey.progressTitle') }</ProgressTitle>
          <ProgressPercentile>{progressPercentile}%</ProgressPercentile>
        </ProgressText>
        <Progress>
          <CurrentProgress progress={progressPercentile} />
        </Progress>
      </ProgressDiv>
    );
  } else {
    return <Submit onClick={submitAnswers}>{ t('body.survey.submitButton') }</Submit>;
  }
}

const Questions = styled.div`
  display: flex;
  height: calc(var(--vh, 1vh) * 100 - 114px);
  flex-direction: column;
  width: 100%;
  margin-top: 43px;
  scroll-behavior: smooth;
  overflow-y: auto;
`;

const ProgressDiv = styled.div`
  background-color: #f4f4f4;
  position: fixed;
  bottom: 0;
  width: 100%;
  height: 71px;
  max-width: 480px;
  overflow: hidden;
`;

const ProgressText = styled.div`
  display: flex;
  gap: 20px;
  font-size: 9px;
  color: #6c625c;
  font-weight: 600;
  letter-spacing: -0.18px;
  line-height: 178%;
  margin-top: 16px;
  justify-content: space-between;
`;

const ProgressTitle = styled.div`
  font-family: MaruBuri, sans-serif;
  font-weight: 700;
  margin-left: 28px;
`;

const ProgressPercentile = styled.div`
  color: #6c625c;
  text-align: right;
  font-family: MaruBuri, sans-serif;
  font-weight: 700;
  margin-right: 28px;
`;

const Progress = styled.div`
  border-radius: 30px;
  background-color: #e6e6e6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 7px 28px;
`;

const CurrentProgress = styled.div`
  border-radius: 30px;
  background-color: #fce4e5;
  width: ${(props) => props.progress.toString() + "%"};
  height: 7px;
`;

const Submit = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fce4e5;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 480px;
  height: 71px;
  color: #6c625c;
  font: 600 20px MaruBuri, sans-serif;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

export default Survey;

import React, { useEffect } from "react";
import "./App.css";
import Router from "./Router";
import { useTranslation } from 'react-i18next';

function App() {
  const { t, i18n } = useTranslation();

  console.log(i18n.language);

  useEffect(() => {
    document.documentElement.lang = i18n.language;
    document.title = t('header.title'); // title 변경
  }, [i18n.language, t]);
  
  useEffect(() => {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }, []);

  return <Router></Router>;
}

export default App;

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import AdComponent from "../../components/AdComponent";
import { useTranslation } from "react-i18next";

function Enquete() {
  const [gender, setGender] = useState('');
  const [age, setAge] = useState('');
  const [isNextButtonDisabled, setNextButtonDisabled] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const shouldDisable = gender.trim() === '' || isNaN(age.trim()) || age.trim() === '';
    setNextButtonDisabled(shouldDisable);
  }, [gender, age]);

  const navigateToExplain = () => {
    sendUserData(gender, age);
    navigate("/explain");
  };

  async function sendUserData(gender, age) {
      let genderKo = gender;
      
      if (genderKo === 'man' || genderKo === '男') {
        genderKo = '남';
      } else {
        genderKo = '여';
      }
      try {
          const response = await axios.post('https://couple-attachment.com/api/client-info', {
              gender: genderKo,
              age: age
          });
      } catch (error) {
      }
  }

  const handleGenderInputEvent = (event) => {
    const value = event.target.textContent;
    setGender(value);
  }

  const handleAgeInputEvent = (event) => {
    const value = event.target.value;
    setAge(value);
  }

  const adHtml = `<ins class="kakao_ad_area" style="display:none;" data-ad-unit = "DAN-Xz1NurhNFVnvBFO7" data-ad-width = "320" data-ad-height = "50"></ins>
  <script type="text/javascript" src="//t1.daumcdn.net/kas/static/ba.min.js" async></script>
  <iframe src="https://ads-partners.coupang.com/widgets.html?id=782099&template=carousel&trackingCode=AF9744820&subId=&width=320&height=0&tsource=" width="320" height="50" frameborder="0" scrolling="no" referrerpolicy="unsafe-url" browsingtopics></iframe>`;

  return (
    <div style={{position: "fixed"}}>
      <Title>{ t('body.enquete.title') }</Title>
      <Question1>{ t('body.enquete.question1') }</Question1>
      <GenderGroup>
        <GenderCheckbox onClick={handleGenderInputEvent} checked={gender === t('body.enquete.gender.man') ? true : false}>{ t('body.enquete.gender.man') }</GenderCheckbox>
        <GenderCheckbox onClick={handleGenderInputEvent} checked={gender === t('body.enquete.gender.woman') ? true : false}>{ t('body.enquete.gender.woman') }</GenderCheckbox>
      </GenderGroup>
      <Question2>{ t('body.enquete.question2') }</Question2>
      <AgeDiv>
        <AgeInput placeholder="27" onChange={handleAgeInputEvent}></AgeInput>
        <AgeUnderBar></AgeUnderBar>
      </AgeDiv>
      <SupportDiv>
        <NextButton onClick={navigateToExplain} disabled={isNextButtonDisabled}>{ t('body.enquete.nextButton') }</NextButton>
        <AdComponent bottom="30px"></AdComponent>
        <Support>{ t('body.common.support') }</Support>
      </SupportDiv>
    </div>
  );
}

const SupportDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: 0 auto;
`;

const AgeDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  margin: 0 auto;
`;

const Title = styled.div`
  position: relative;
  text-align: center;
  margin-top: 50px;
  font: 600 23px MaruBuri, sans-serif;
  color: #6c625c;
`;

const Question1 = styled.div`
  position: relative;
  text-align: center;
  font: 600 14px MaruBuri, sans-serif;
  margin-top: 32px;
  letter-spacing: -2%;
  color: #6C625C;
`;

const Question2 = styled.div`
  position: relative;
  text-align: center;
  font: 600 14px MaruBuri, sans-serif;
  margin-top: 33px;
  letter-spacing: -2%;
  color: #6C625C;
`;

const GenderGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 48px;
  margin-top: 32px;
`;

const GenderCheckbox = styled.div`
  position: relative;
  text-align: center;
  font: 600 18px MaruBuri, sans-serif;
  height: 72px;
  width: 72px;
  border-radius: 50%;
  border: none;
  background: ${(props) => (props.checked ? "#8C8B8A" : "#E7E7E7")};
  line-height: 72px;
  color: ${(props) => (props.checked ? "#FFFFFF" : "#413935")};
  word-wrap: break-word;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const AgeInput = styled.input`
  display: flex;
  width: 183px;
  margin-top: 34px;
  border: none;
  font: 600 20px MaruBuri, sans-serif;
  color: #6C625C;
  text-align: center;
  background: none;

  &::placeholder {
    color: #C4C4C4;
  }
`;

const AgeUnderBar = styled.div`
  margin-top: 5px;
  width: 183px;
  height: 1px;
  background: #6F6F6F;
`;

const NextButton = styled.button`
  position: relative;
  width: 253px;
  height: 60px;
  margin-top: 34px;
  margin-bottom: 20px;
  border-radius: 10px;
  border: none;
  background: ${(props) => (props.disabled ? "#E7E7E7" : "#fce4e5")};
  text-align: center;
  color: ${(props) => (props.disabled ? "#A3A2A2" : "#6c625c")};
  font: 600 16px MaruBuri, sans-serif;
  word-wrap: break-word;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const Support = styled.div`
  position: fixed;
  color: #6C625C;
  text-align: center;
  letter-spacing: -0.24px;
  bottom: 10px;
  font: 600 11px MaruBuri, sans-serif;
`;

export default Enquete;

import styled from "styled-components";

const Background = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-width: 480px;
  aspect-ratio: 0.46;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  background-image: url("/image/background/paper-texture.png");
`;

export default Background;
import React, { useEffect } from "react"
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "react-i18next";

function Audio(props) {
  const imagePath = props.isPlay
    ? "image/audio/audio-play.png"
    : "image/audio/audio-mute.png";

  const location = useLocation();
  const hiddenRoutes = ["/result", "/result/details", "/result/compatibility"];
  const isVisible = !hiddenRoutes.includes(location.pathname);
  const { t } = useTranslation();

  useEffect(() => {
    toast.info(t('body.common.audioToast'), {
      className: 'audio-toast',
      bodyClassName: 'audio-toast-body',
      progressClassName: 'audio-toast-progress',
    });
  }, []);

  return (
    <DivAudio
      onClick={() => {
        props.onChangeMode();
      }}
      isVisible={isVisible}
    >
      <ImgAudio loading="lazy" src={imagePath} />
      <audio src="sound/background-sound.mp3" id="bgm" loop></audio>
      <ToastContainer
          position="bottom-right"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick={true}
          rtl={false}
          pauseOnFocusLoss={false}
          draggable={false}
          pauseOnHover={false}
          theme="light"
        />
    </DivAudio>
  );
}

const ImgAudio = styled.img`
  position: absolute;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 20px;
  height: 20px;
`;

const DivAudio = styled.div`
  display: ${(props) => (props.isVisible ? "flex" : "none")};
  position: fixed;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  margin-top: 16px;
  margin-right: 16px;
  border: 2px #a2a2a2 solid;
  border-radius: 50%;
  align-self: end;
  z-index: 9999;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

export default Audio;

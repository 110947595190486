import React, { useEffect } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function KakaotalkShare() {
  const { t } = useTranslation();
  useEffect(() => {
    // Kakao 스크립트 로드 함수
    const loadKakaoScript = () => {
      const script = document.createElement("script");
      script.src = "https://t1.kakaocdn.net/kakao_js_sdk/2.7.1/kakao.min.js";
      script.integrity =
        "sha384-kDljxUXHaJ9xAb2AzRd59KxjrFjzHa5TAoFQ6GbYTCAG0bjM55XohjjDT7tDDC01";
      script.crossOrigin = "anonymous";
      script.onload = () => initializeKakao();
      script.onerror = () => console.error("Failed to load the Kakao SDK");
      document.head.appendChild(script);
    };

    // Kakao SDK 초기화 함수
    const initializeKakao = () => {
      if (window.Kakao && !window.Kakao.isInitialized()) {
        window.Kakao.init("c67ab0cf0992fbe5f1a0b68b41a9f22e");
      }
    };

    if (!window.Kakao) {
      loadKakaoScript();
    } else {
      initializeKakao();
    }
  }, []);

  const handleShare = () => {
    window.Kakao.Share.sendDefault({
      objectType: "feed",
      content: {
        title: "성인 애착 유형 테스트",
        description: "수정구슬로 알아보는 나의 애착 유형",
        imageUrl:
          "https://i.ibb.co/9tz2cPQ/image.jpg",
        link: {
          // [내 애플리케이션] > [플랫폼] 에서 등록한 사이트 도메인과 일치해야 함
          mobileWebUrl: "https://couple-attachment.com",
          webUrl: "https://couple-attachment.com",
        },
      },
      buttons: [
        {
          title: "자세히 보기",
          link: {
            mobileWebUrl: "https://couple-attachment.com",
            webUrl: "https://couple-attachment.com",
          },
        },
      ],
    });
  };

  return (
    <KakaotalkShareDiv onClick={handleShare}>
      <KakaotalkImage loading="lazy" src="image/share/kakaotalk.png" />
      <KakaotalkShareText>{ t('body.share.kakao') }</KakaotalkShareText>
    </KakaotalkShareDiv>
  );
}

const KakaotalkShareDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (hover: hover) and (pointer: fine) {
      &:hover {
        cursor: pointer;
        opacity: 0.5;
      }
    }
`;

const KakaotalkImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 43px;
  height: 43px;
  display: block;
  margin-bottom: 11px;
`;

const KakaotalkShareText = styled.div`
  text-align: center;
  color: #6C625C;
  font: 600 11px MaruBuri, sans-serif;
  word-wrap: break-word;
`;

export default KakaotalkShare;

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from '../../src/locales/en/translation.json'
import translationKO from '../../src/locales/ko/translation.json'
import translationJA from '../../src/locales/ja/translation.json'

const resources = {
  ko: {
    translation: translationKO
  },
  en: {
    translation: translationEN
  },
  ja: {
    translation: translationJA
  }
}

i18n.use(initReactI18next).init({
  resources: resources,
  lng: navigator.language.split('-')[0], // 브라우저 언어로 초기화
  fallbackLng: 'en',

  interpolation: {
    escapeValue: false
  }
});

export default i18n;

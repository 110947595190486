import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ResultFooter() {
  const navigate = useNavigate();
  const location = useLocation();
  const [clickedIndex, setClickedIndex] = useState(0);
  const { t } = useTranslation();

  const retry = () => {
    if(window.confirm(t('body.result.header.retryConfirm'))) {
    	navigate("/");
    }
  };

  const navigateToMainResult = () => {
    navigate("/result" + location.search);
    setClickedIndex(0);
  };

  const navigateToDetails = () => {
    navigate("/result/details" + location.search);
    setClickedIndex(1);
  };

  const navigateToCompatibility = () => {
    navigate("/result/compatibility" + location.search);
    setClickedIndex(2);
  };

  const shareResult = () => {
    navigator.clipboard.writeText(
      "https://couple-attachment.com/result" + location.search
    );
    alert(t('body.result.header.linkCopy'));
  };

  useEffect(() => {
    toast.info(t('body.result.header.resultToast'), {
      className: 'result-toast',
      bodyClassName: 'result-toast-body',
      progressClassName: 'result-toast-progress',
    });
  }, []);

  return (
    <>
      <Header>
        <MainResultDiv onClick={navigateToMainResult}>
          <MainResultImage
            loading="lazy"
            src={
              clickedIndex === 0
                ? "/image/result/header/main-result-thick.png"
                : "/image/result/header/main-result.png"
            }
          />
          <MainResultText>{ t('body.result.header.mainResultText') }</MainResultText>
        </MainResultDiv>
        <DetailScoreDiv onClick={navigateToDetails}>
          <DetailScoreImage
            loading="lazy"
            src={
              clickedIndex === 1
                ? "/image/result/header/detail-score-thick.png"
                : "/image/result/header/detail-score.png"
            }
          />
          <DetailScoreText>{ t('body.result.header.detailScoreText') }</DetailScoreText>
        </DetailScoreDiv>
        <CompatibilityDiv onClick={navigateToCompatibility}>
          <CompatibilityImage
            loading="lazy"
            src={
              clickedIndex === 2
                ? "/image/result/header/compatibility-thick.png"
                : "/image/result/header/compatibility.png"
            }
          />
          <CompatibilityText>{ t('body.result.header.compatibilityText') }</CompatibilityText>
        </CompatibilityDiv>
        <ResultShareDiv onClick={shareResult}>
          <ResultShareImage
            loading="lazy"
            src="/image/result/header/result-share.png"
          />
          <ResultShareText>{ t('body.result.header.resultShareText') }</ResultShareText>
        </ResultShareDiv>
        <RetryDiv onClick={retry}>
          <RetryImage
            loading="lazy"
            src="/image/result/header/retry.png"
          />
          <RetryText>{ t('body.result.header.retryText') }</RetryText>
        </RetryDiv>
      </Header>
      <ToastContainer
      position="bottom-right"
      autoClose={2000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={true}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
      theme="light"
        />
    </>
  );
}

const Header = styled.div`
  position: fixed;
  top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 360px;
  height: 70px;
`;

const MainResultDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const DetailScoreDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const CompatibilityDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const ResultShareDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const RetryDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const MainResultImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 30px;
`;

const DetailScoreImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 30px;
`;

const CompatibilityImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 30px;
`;

const ResultShareImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 30px;
`;

const RetryImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 30px;
`;

const MainResultText = styled.div`
  color: #747474;
  font: 800 11px MaruBuri, sans-serif;
  font-size: ${navigator.language.split('-')[0] === 'en' ? '10px' : '11px'};
  text-align: center;
  white-space: pre;
`;

const DetailScoreText = styled.div`
  color: #747474;
  font: 800 11px MaruBuri, sans-serif;
  font-size: ${navigator.language.split('-')[0] === 'en' ? '10px' : '11px'};
  text-align: center;
  white-space: pre;
`;

const CompatibilityText = styled.div`
  color: #747474;
  font: 800 11px MaruBuri, sans-serif;
  font-size: ${navigator.language.split('-')[0] === 'en' ? '10px' : '11px'};
  text-align: center;
  white-space: pre;
`;

const ResultShareText = styled.div`
  color: #747474;
  font: 800 11px MaruBuri, sans-serif;
  font-size: ${navigator.language.split('-')[0] === 'en' ? '10px' : '11px'};
  text-align: center;
  white-space: pre;
`;

const RetryText = styled.div`
  color: #747474;
  font: 800 11px MaruBuri, sans-serif;
  font-size: ${navigator.language.split('-')[0] === 'en' ? '10px' : '11px'};
  text-align: center;
  white-space: pre;
`;

export default ResultFooter;

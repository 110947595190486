import React from "react";
import typeExplain from "../../data/typeExplain";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import Modal from "react-modal";

Modal.setAppElement("#root");

function MainResult() {
  const [query, setQuery] = useSearchParams();
  const type = query.get("type");

  return (
    <>
      <TypeImage
        loading="lazy"
        src={"image/type/" + typeExplain[type].type + "-type.png"}
      />
      <TypeName>{typeExplain[type].name}</TypeName>
      <TypeExplain>{typeExplain[type].explain}</TypeExplain>
    </>
  );
}

const TypeImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 142px;
  margin-top: 103px;
`;

const TypeName = styled.div`
  color: #6c625c;
  letter-spacing: -0.6px;
  margin-top: 8px;
  font: 600 23px MaruBuri, sans-serif;
  white-space: pre-wrap;
  text-align: center;
`;

const TypeExplain = styled.div`
  color: #6c625c;
  text-align: center;
  letter-spacing: -0.3px;
  max-width: ${navigator.language.split('-')[0] === 'en' ? '360px' : '300px'};
  margin-top: 16px;
  font: 600 14px/25px MaruBuri, sans-serif;
`;

export default MainResult;

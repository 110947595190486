import { useState } from "react";
import { Outlet } from "react-router-dom";
import Audio from "../../components/Audio";
import Background from "../../components/Background";

function Home() {
  const [isPlay, setIsPlay] = useState(false);

  const playMusic = () => {
    setIsPlay(!isPlay);
    if (!isPlay) {
      document.getElementById("bgm").play();
    } else {
      document.getElementById("bgm").pause();
    }
  };

  return (
      <Background>
        <Audio isPlay={isPlay} onChangeMode={playMusic} />
        <Outlet />
      </Background>
  );
}

export default Home;

import typeCompatibilityKO from "../locales/ko/typeCompatibility"
import typeCompatibilityEN  from "../locales/en/typeCompatibility"
import typeCompatibilityJA from "../locales/ja/typeCompatibility"

const lang = navigator.language.split('-')[0];

const getTypeCompatibilityListByLang = () => {
    if (lang === 'ko') {
        return typeCompatibilityKO;
    } else if (lang === 'ja') {
        return typeCompatibilityJA;
    }
    return typeCompatibilityEN;
}

const typeCompatibility  = getTypeCompatibilityListByLang(lang);

export default typeCompatibility;
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import AdComponent from "../../components/AdComponent";
import { useTranslation } from "react-i18next";

function SurveyExplain() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const startConfirm = () => {
    navigate("/Questions");
  };

  return (
    <>
      <Title>{ t('body.surveyExplain.title') }</Title>
      <Explain1>{ t('body.surveyExplain.explain1') }</Explain1>
      <Explain2>{ t('body.surveyExplain.explain2') }</Explain2>
      <Explain3>{ t('body.surveyExplain.explain3') }</Explain3>
      <StartConfirmImage
        src={ t('body.surveyExplain.imagePath') + "start-confirm.png" }
        onClick={startConfirm}
      />
      <AdComponent bottom="30px"></AdComponent>
      <Support>{ t('body.common.support') }</Support>
    </>
  );
}

const Title = styled.div`
  position: relative;
  text-align: center;
  margin-top: 50px;
  font: 600 23px MaruBuri, sans-serif;
  color: #6C625C;
`;

const Explain1 = styled.div`
  position: relative;
  text-align: center;
  font: 600 14px MaruBuri, sans-serif;
  line-height: 24px;
  align-self: stretch;
  margin-top: 48px;
  white-space: pre-wrap;
  color: #6C625C;
`;

const Explain2 = styled.div`
  position: relative;
  text-align: center;
  font: 600 14px MaruBuri, sans-serif;
  line-height: 24px;
  align-self: stretch;
  margin-top: 44px;
  white-space: pre-wrap;
  color: #6C625C;
`;

const Explain3 = styled.div`
  position: relative;
  text-align: center;
  font: 600 14px MaruBuri, sans-serif;
  line-height: 24px;
  margin-top: 44px;
  color: #6C625C;
`;

const Support = styled.div`
  position: fixed;
  color: #6C625C;
  text-align: center;
  letter-spacing: -0.24px;
  bottom: 10px;
  font: 600 11px MaruBuri, sans-serif;
`;

const StartConfirmImage = styled.img`
  position: relative;
  inset: 0;
  object-fit: cover;
  object-position: center;
  height: 61px;
  margin-top: 50px;
  margin-bottom: 26px;
  left: -12px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

export default SurveyExplain;

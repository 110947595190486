import questionListKO from "../locales/ko/questionList";
import questionListEN from "../locales/en/questionList";
import questionListJA from "../locales/ja/questionList";

const lang = navigator.language.split('-')[0];

const getQuestionListByLang = () => {
    if (lang === 'ko') {
        return questionListKO;
    } else if (lang === 'ja') {
        return questionListJA;
    }
    return questionListEN;
}

const questionList = getQuestionListByLang(lang);

export default questionList;
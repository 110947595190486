import { useState } from "react";
import styled from "styled-components";
import KakaotalkShare from "../../components/KakaotalkShare";
import URLShare from "../../components/URLShare";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";

function Intro() {
  const { t } = useTranslation();

  return (
    <>
      <SupportSentence>{ t('body.start.supportSentence') }</SupportSentence>
      <TopicSentence>{ t('body.start.topicSentence') }</TopicSentence>
    </>
  );
}

function Start() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [visitCount, setVisitCount] = useState(0);

  const navigateToEnquete = () => {
    navigate("/enquete");
  };

  async function getVisitCount(
  ) {
    try {
      const response = await axios.get(
        "https://couple-attachment.com/api/visit-count"
      );
      setVisitCount(response.data);
    } catch (error) {
    }
  }
  getVisitCount();

  return (
    <>
      <Intro></Intro>
      <MarbleImage loading="lazy" src="image/main/marble.png" />
      <VisitDiv>
        <CheckMarkImage loading="lazy" src="image/main/check-mark.png" />
        <VisitCount>{ t('body.start.visitCountPrefix') +  (visitCount + 350) + t("body.start.visitCountSuffix") }</VisitCount>
      </VisitDiv>
      <StartButton onClick={navigateToEnquete}>{ t('body.start.startButton') }</StartButton>
      <ShareDiv>
        <KakaotalkShare></KakaotalkShare>
        <URLShare></URLShare>
      </ShareDiv>
      <Support>{ t('body.common.support') }</Support>
    </>
  );
}

const SupportSentence = styled.div`
  position: relative;
  color: #6c625c;
  text-align: center;
  letter-spacing: -0.3px;
  white-space: pre-wrap;
  margin-top: 64px;
  font: 600 13px/20px MaruBuri, sans-serif;
`;

const TopicSentence = styled.div`
  position: relative;
  color: #6c625c;
  text-align: center;
  letter-spacing: -0.56px;
  margin-top: 26px;
  font: 600 23px MaruBuri, sans-serif;
`;

const MarbleImage = styled.img`
  position: relative;
  object-fit: auto;
  object-position: center;
  width: 100%;
  margin-top: 24px;
  max-width: 250px;
`;

const VisitDiv = styled.div`
  display: flex;
  align-items: center;
  line-height: 12px;
  max-height: 12px;
  margin-top: 45px;
`;

const VisitCount = styled.div`
  color: #6c625c;
  text-align: center;
  font: 800 11px MaruBuri, sans-serif;
`;

const CheckMarkImage = styled.img`
  position: relative;
  object-fit: auto;
  object-position: center;
  margin-right: 5px;
  width: 12px;
  height: 12px;
`;

const StartButton = styled.button`
  position: relative;
  width: 253px;
  height: 60px;
  margin-top: 10px;
  border-radius: 10px;
  border: none;
  background: #fce4e5;
  text-align: center;
  color: #6c625c;
  font: 600 16px MaruBuri, sans-serif;
  word-wrap: break-word;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const ShareDiv = styled.div`
  position: relative;
  display: flex;
  margin-top: 34px;
  gap: 40px;
  justify-content: space-between;
`;

const Support = styled.div`
  position: fixed;
  color: #6C625C;
  text-align: center;
  letter-spacing: -0.24px;
  bottom: 10px;
  font: 600 11px MaruBuri, sans-serif;
`;

export default Start;

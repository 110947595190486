import React, { useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

function QuestionItem(props) {
  const questionState = useSelector((state) => state.questionState);
  const answerScores = useSelector((state) => state.answerScores);

  const [checkedList, setCheckedList] = useState([
    false,
    false,
    false,
    false,
    false,
  ]);

  const stronglyDisagreeImage = "image/answers/strongly-disagree.png";
  const disagreeImage = "image/answers/disagree.png";
  const neutralImage = "image/answers/neutral.png";
  const agreeImage = "image/answers/agree.png";
  const stronglyAgreeImage = "image/answers/strongly-agree.png";
  const notSelectedImage = "image/answers/not-selected.png";
  const reversalQuestion = [3, 15, 19, 22, 25, 27, 29, 31, 33, 35];

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const updateState = (score) => {
    const updatedCheckedList = checkedList.map((item, idx) =>
      idx === score - 1 ? true : false
    );
    setCheckedList(updatedCheckedList);

    const newAnswerScores = [...answerScores];
    newAnswerScores[props.question.id] = reversalQuestion.includes(Number(props.question.id)) ? 6 - score : score;
    dispatch({ type: "SET_ANSWER_SCORE", payload: newAnswerScores });

    if (Number(props.question.id) === questionState.lastId) {
      const newState = questionState;
      newState.lastId++;
      dispatch({ type: "SET_QUESTION_STATE", payload: newState });
    }
  };

  return (
    <QusetionContainer>
      <Question>
        <QuestionNumber>{props.question.id}</QuestionNumber>
        <QuestionText>{props.question.question}</QuestionText>
      </Question>
      <Answers>
        <Answer onClick={() => updateState(1)} checked={checkedList[0]}>
          <Img
            loading="lazy"
            src={checkedList[0] ? stronglyDisagreeImage : notSelectedImage}
          />
          <AnserText>{ t('body.answer.stronglyDisagree') }</AnserText>
        </Answer>
        <Answer onClick={() => updateState(2)} checked={checkedList[1]}>
          <Img
            loading="lazy"
            src={checkedList[1] ? disagreeImage : notSelectedImage}
          />
          <AnserText>{ t('body.answer.disagree') }</AnserText>
        </Answer>
        <Answer onClick={() => updateState(3)} checked={checkedList[2]}>
          <Img
            loading="lazy"
            src={checkedList[2] ? neutralImage : notSelectedImage}
          />
          <AnserText>{ t('body.answer.neutral') }</AnserText>
        </Answer>
        <Answer onClick={() => updateState(4)} checked={checkedList[3]}>
          <Img
            loading="lazy"
            src={checkedList[3] ? agreeImage : notSelectedImage}
          />
          <AnserText>{ t('body.answer.agree') }</AnserText>
        </Answer>
        <Answer onClick={() => updateState(5)} checked={checkedList[4]}>
          <Img
            loading="lazy"
            src={checkedList[4] ? stronglyAgreeImage : notSelectedImage}
          />
          <AnserText>{ t('body.answer.stronglyAgree') }</AnserText>
        </Answer>
      </Answers>
    </QusetionContainer>
  );
}

const QusetionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 9px;
  color: #a7a7a7;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.18px;
  padding: 0 20px;
  height: 137px;
  margin-top: 13px;
  margin-bottom: 42px;
`;

const Question = styled.div`
  font-size: 14px;
  color: #6c625c;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.26px;
  padding: 0 20px;
`;

const QuestionNumber = styled.div`
  font-family: MaruBuri, sans-serif;
  align-self: center;
`;

const QuestionText = styled.div`
  font-family: MaruBuri, sans-serif;
  white-space: pre-wrap;
  margin-top: 18px;
  width: 100%;
`;

const Answers = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: center;
`;

const Answer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 9px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const Img = styled.img`
  object-fit: auto;
  object-position: center;
  width: 53px;
  align-self: center;
`;

const AnserText = styled.div`
  font-family: MaruBuri, sans-serif;
  font-weight: 600;
  color: #6C625C;
  margin-top: 4px;
  white-space: pre-wrap;
`;

export default QuestionItem;

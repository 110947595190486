import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import typeExplain from "../../data/typeExplain";
import Modal from "react-modal";
import SelectStyleModal from "../../components/SelectStyleModal";
import typeCompatibility from "../../data/typeCompatibility";
import { useTranslation } from "react-i18next";

Modal.setAppElement("#root");

function Compatibility() {
  const [query, setQuery] = useSearchParams();
  const myType = query.get("type");
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [loverType, setLoverType] = React.useState(-1);
  const { t } = useTranslation();

  const customStyles = {
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.4)",
    },
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "17px",
      width: "280px",
      height: "260px",
      maxWidth: "320px",
      maxHeight: "320px",
      paddingBottom: "40px",
    },
  };

  function openModal() {
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function selectAttachmentStyle(type) {
    setLoverType(type);
  }

  function afterOpenModal() {
    console.log("open modal!");
  }

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
        contentLabel="CompatibilityModal"
      >
        <SelectStyleModal
          close={closeModal}
          selectAttachmentStyle={selectAttachmentStyle}
        ></SelectStyleModal>
      </Modal>
      <MeAndLoverDiv>
        <WhoDiv>
          <WhoText>{ t('body.result.compatibility.me') }</WhoText>
          <MyTypeImage
            loading="lazy"
            src={"/image/type/" + typeExplain[myType].type + "-type.png"}
          />
          <TypeText>{typeExplain[myType].name}</TypeText>
        </WhoDiv>
        <WhoDiv>
          <HeartImage loading="lazy" src="/image/result/heart.png" />
        </WhoDiv>
        <WhoDiv>
          <WhoText>{ t('body.result.compatibility.otherPerson') }</WhoText>
          <LoverTypeImage
            loading="lazy"
            src={
              loverType === -1
                ? "/image/result/empty-marble.png"
                : "/image/type/" +
                  typeCompatibility[myType].lover[loverType].type +
                  "-type.png"
            }
            onClick={openModal}
          />
          <TypeText>
            {loverType === -1
              ? "?"
              : typeCompatibility[myType].lover[loverType].name}
          </TypeText>
        </WhoDiv>
      </MeAndLoverDiv>
      <ExplainText lovertype={loverType}>
        {loverType === -1
          ? t('body.result.compatibility.explain')
          : typeCompatibility[myType].lover[loverType].explain}
      </ExplainText>
    </>
  );
}

const HeartImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 23px;
  height: 23px;
  margin-top: 70px;
`;

const MyTypeImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 99px;
`;

const LoverTypeImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 99px;
  height: 108px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const MeAndLoverDiv = styled.div`
  display: flex;
  flex-direction: row;
  color: #6C625C;
  font: 600 14px MaruBuri, sans-serif;
  justify-content: center;
`;

const WhoDiv = styled.div`
  display: flex;
  flex-direction: column;
  margin: 128px 11px 0px 11px;
`;

const WhoText = styled.div`
  color: #6C625C;
  font: 600 14px MaruBuri, sans-serif;
  align-self: center;
`;

const TypeText = styled.div`
  color: "#6C625C";
  font: 600 14px MaruBuri, sans-serif;
  margin-top: 6px;
  align-self: center;
  white-space: pre;
  text-align: center;
`;

const ExplainText = styled.div`
  color: ${(props) => ( props.lovertype === -1 ? "#999898" : "#6C625C")};
  align-self: center;
  max-width: ${navigator.language.split('-')[0] === 'en' ? '360px' : '270px'};
  white-space: pre-wrap;
  margin-top: ${(props) => ( props.lovertype === -1 ? "97px" : "38px")};
  font: 600 14px/25px MaruBuri, sans-serif;
  text-align: center;
`;

export default Compatibility;

import styled from "styled-components";
import useScript from "../hooks/useScript"
import { useTranslation } from "react-i18next";

const AdComponent = (props) => {
  useScript("//t1.daumcdn.net/kas/static/ba.min.js");
  const { t } = useTranslation();
  
  return (
    <AdDiv bottom={props.bottom ? props.bottom : "0px"}>
      <ins class="kakao_ad_area" style={{display : "none"}} data-ad-unit = "DAN-Xz1NurhNFVnvBFO7" data-ad-width = "320" data-ad-height = "50"></ins>
      <iframe src="https://ads-partners.coupang.com/widgets.html?id=782099&template=carousel&trackingCode=AF9744820&subId=&width=320&height=50&tsource=" width="320" height="50" frameborder="0" scrolling="no" referrerpolicy="unsafe-url" browsingtopics></iframe>
      <p style={{fontSize: "8px", color: "#999898"}}>{ t('body.ad.coupang') }</p>
    </AdDiv>
  );
}

const AdDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  align-items: center;
  bottom: ${(props) => props.bottom}
`

export default AdComponent;
import React from "react";
import styled from "styled-components";
import { useSearchParams } from "react-router-dom";
import typeExplain from "../../data/typeExplain";
import { useTranslation } from "react-i18next";

function Details() {
  const [query, setQuery] = useSearchParams();
  const type = query.get("type");
  const avoidantScore = query.get("avg_odd_score") * 18;
  const anxiousScore = query.get("avg_even_score") * 18;
  const { t } = useTranslation();

  const calculateAvoidantPosition = () => {
    if (avoidantScore >= 41) {
      return 50 + (avoidantScore - 41) * 1.02;
    } else {
      return (avoidantScore - 18) * 2.174;
    }
  };

  const calculateAnxiousPosition = () => {
    if (anxiousScore >= 46) {
      return 50 + (anxiousScore - 46) * 1.136;
    } else {
      return (anxiousScore - 18) * 1.785;
    }
  };

  return (
        <>
          <TypeExplain1>
            { t('body.result.details.typeExplain1-1') }
            <span style={{ color: typeExplain[type].color }}>
              {avoidantScore}
            </span>
            { t('body.result.details.typeExplain1-2') }
            <span style={{ color: typeExplain[type].color }}>{anxiousScore}</span>
            { t('body.result.details.typeExplain1-3') }
          </TypeExplain1>
          <TypeExplain2>{ t('body.result.details.typeExplain2') }</TypeExplain2>
          <AttachmentStyleMatrix>
            <HighAvoidance>{ t('body.result.details.highAvoidance') }</HighAvoidance>
            <Matrix>
              <LowAnxiety>{ t('body.result.details.lowAnxiety') }</LowAnxiety>
              <InnerMatrix>
                <PointImage
                  loading="lazy"
                  src={"/image/result/" + typeExplain[type].type + "-point.png"}
                  avoidantposition={calculateAvoidantPosition(avoidantScore)}
                  anxiousposition={calculateAnxiousPosition(anxiousScore)}
                />
                <Row1>
                  <AvoidantType>{ t('body.result.details.avoidantType') }</AvoidantType>
                  <FearfulAvoidantType>{ t('body.result.details.fearfulAvoidantType') }</FearfulAvoidantType>
                </Row1>
                <Row2>
                  <SecureType>{ t('body.result.details.secureType') }</SecureType>
                  <AnxiousType>{ t('body.result.details.anxiousType') }</AnxiousType>
                </Row2>
              </InnerMatrix>
              <HighAnxiety>{ t('body.result.details.highAnxiety') }</HighAnxiety>
            </Matrix>
            <LowAvoidance>{ t('body.result.details.lowAvoidance') }</LowAvoidance>
          </AttachmentStyleMatrix>
        </>
  );
}

const TypeExplain1 = styled.div`
  color: #6c625c;
  letter-spacing: -0.26px;
  align-self: stretch;
  width: 100%;
  text-align: center;
  margin-top: 130px;
  font: 600 14px MaruBuri, sans-serif;
`;

const TypeExplain2 = styled.div`
  letter-spacing: -0.2px;
  margin-top: 5px;
  text-align: center;
  color: #999898;
  font: 400 12px/20px MaruBuri, sans-serif;
`;

const AttachmentStyleMatrix = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  align-content: center;
  justify-content: center;
  font-size: 12px;
  color: #C9C9C9;
  font-weight: 600;
  text-align: center;
  letter-spacing: -0.24px;
  line-height: 250%;
  margin-top: 10px;
`;

const HighAvoidance = styled.div`
  font-family: MaruBuri, sans-serif;
  color: #747474;
`;

const Matrix = styled.div`
  position: relative;
  align-self: stretch;
  width: 100%;
  gap: 10px;
  display: flex;
  margin-top: 9px;
  align-items: center;
  white-space: nowrap;
  line-height: 15px;
`;

const LowAnxiety = styled.div`
  font-family: MaruBuri, sans-serif;
  align-self: stretch;
  color: #747474;
  margin: auto 0;
  white-space: pre;
`;

const InnerMatrix = styled.div`
  background-color: #f4f4f4;
  position: relative;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  font-size: 15px;
  color: #C9C9C9;
  letter-spacing: -0.3px;
  line-height: 200%;
  width: 100%;
`;

const Row1 = styled.div`
  display: flex;
`;

const AvoidantType = styled.div`
  font-family: MaruBuri, sans-serif;
  background-color: #edfaff;
  color: #C9C9C9;
  align-items: center;
  width: 50%;
  justify-content: center;
  height: 125px;
  line-height: 125px;
  white-space: pre;
`;

const FearfulAvoidantType = styled.div`
  font-family: MaruBuri, sans-serif;
  background-color: #faf3ff;
  color: #C9C9C9;
  align-items: center;
  width: 50%;
  justify-content: center;
  height: 125px;
  line-height: 125px;
  white-space: pre;
`;

const Row2 = styled.div`
  display: flex;
`;

const SecureType = styled.div`
  font-family: MaruBuri, sans-serif;
  background-color: #f3fef3;
  color: #C9C9C9;
  align-items: center;
  width: 50%;
  justify-content: center;
  height: 125px;
  line-height: 125px;
`;

const AnxiousType = styled.div`
  font-family: MaruBuri, sans-serif;
  background-color: #fef5ee;
  color: #C9C9C9;
  align-items: center;
  width: 50%;
  justify-content: center;
  height: 125px;
  line-height: 125px;
`;

const HighAnxiety = styled.div`
  font-family: MaruBuri, sans-serif;
  color: #747474;
  align-self: stretch;
  margin: auto 0;
  white-space: pre;
`;

const LowAvoidance = styled.div`
  font-family: MaruBuri, sans-serif;
  color: #747474;
  margin-top: 10px;
`;

const PointImage = styled.img`
  position: absolute;
  aspect-ratio: 1;
  object-fit: auto;
  object-position: center;
  width: 35px;
  bottom: ${(props) => props.avoidantposition + "%"};
  left: ${(props) => props.anxiousposition + "%"};
  transform: translateX(-50%);
`;

export default Details;

import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import questionList from "./data/questionList";
import Home from "./pages/home/Home";
import Start from "./pages/home/Start";
import Enquete from "./pages/survey-explain/Enquete";
import SurveyExplain from "./pages/survey-explain/SurveyExplain"
import Survey from "./pages/survey/Survey";
import Result from "./pages/result/Result";
import MainResult from "./pages/result/MainResult";
import Details from "./pages/result/Details";
import Compatibility from "./pages/result/Compatibility";

const questionState = {
  lastId: 1,
  lis: [questionList[0]],
};

const answerScores = new Array(questionList.length + 1).fill(0);

function reducer(
  state = { questionState: questionState, answerScores: answerScores },
  action
) {
  switch (action.type) {
    case "SET_QUESTION_STATE":
      if (questionList.length < action.payload.lastId) {
        return { ...state };
      }
      const newLis = [
        ...action.payload.lis,
        questionList[action.payload.lastId - 1],
      ];

      const newQuestionState = {
        ...action.payload,
        lis: newLis,
      };

      state.questionState = newQuestionState;
      return { ...state, questionState: newQuestionState };
    case "SET_ANSWER_SCORE":
      const newAnswerScors = [...action.payload];
      return { ...state, answerScores: newAnswerScors };
    case "INIT":
      const initialQuestionState = {
        lastId: 1,
        lis: [questionList[0]],
      };
      const initialAnswerScores = new Array(questionList.length + 1).fill(0);
      return { questionState: initialQuestionState, answerScores: initialAnswerScores};
    default:
      return { ...state };
  }
}

const store = createStore(reducer);

function Router() {
  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route path="" element={<Start />}></Route>
          <Route path="enquete" element={<Enquete />}></Route>
          <Route path="explain" element={<SurveyExplain />}></Route>
          <Route path="questions" element={<Survey />}></Route>
          <Route path="result" element={<Result />}>
            <Route path="" element={<MainResult />}></Route>
            <Route path="details" element={<Details />}></Route>
            <Route path="compatibility" element={<Compatibility />}></Route>
          </Route>
        </Route>
      </Routes>
    </Provider>
  );
}

export default Router;

import typeExplainKO from "../locales/ko/typeExplain"
import typeExplainEN  from "../locales/en/typeExplain"
import typeExplainJA from "../locales/ja/typeExplain"

const lang = navigator.language.split('-')[0];

const getTypeExplainListByLang = () => {
    if (lang === 'ko') {
        return typeExplainKO;
    } else if (lang === 'ja') {
        return typeExplainJA;
    }
    return typeExplainEN;
}

const typeExplain  = getTypeExplainListByLang(lang);

export default typeExplain;
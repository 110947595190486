import * as React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

function SelectStyleModal(props) {
  const selectAttachmentStyle = (type) => {
    props.selectAttachmentStyle(type);
    props.close();
  };
  const { t } = useTranslation();

  return (
    <>
      <XImage
      loading="lazy"
      src="/image/result/x-icon.png"
      onClick={props.close}
      />
      <StyleModalDiv>
        <Row>
          <TypeDiv onClick={() => selectAttachmentStyle(0)}>
            <TypeImage loading="lazy" src="/image/type/secure-type.png" />
            <TypeText>{ t('body.result.compatibility.secureType') }</TypeText>
          </TypeDiv>
          <TypeDiv onClick={() => selectAttachmentStyle(1)}>
            <TypeImage loading="lazy" src="/image/type/anxious-type.png" />
            <TypeText>{ t('body.result.compatibility.anxiousType') }</TypeText>
          </TypeDiv>
        </Row>
        <Row>
          <TypeDiv onClick={() => selectAttachmentStyle(2)}>
            <TypeImage loading="lazy" src="/image/type/avoidant-type.png" />
            <TypeText>{ t('body.result.compatibility.avoidantType') }</TypeText>
          </TypeDiv>
          <TypeDiv onClick={() => selectAttachmentStyle(3)}>
            <TypeImage
              loading="lazy"
              src="/image/type/fearfulavoidant-type.png"
            />
            <TypeText>{ t('body.result.compatibility.fearfulAvoidantType') }</TypeText>
          </TypeDiv>
        </Row>
      </StyleModalDiv>
    </>
  );
}

const StyleModalDiv = styled.div`
  background-color: #fdfdfd;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 14px;
  white-space: nowrap;
  text-align: center;
  letter-spacing: -0.26px;
  width: 100%;
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const TypeDiv = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

const TypeImage = styled.img`
  object-fit: auto;
  object-position: center;
  width: 90px;
`;

const TypeText = styled.div`
  color: #6c625c;
  font: 600 13px MaruBuri, sans-serif;
  margin-top: 4px;
  white-space: pre;
`;

const XImage = styled.img`
  aspect-ratio: 1;
  object-fit: auto;
  position: fixed;
  top: 13px;
  right: 13px;
  object-position: center;
  width: 30px;
  align-self: end;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;

export default SelectStyleModal;

import React from "react";
import ResultHeader from "../../components/ResultHeader";
import { Outlet } from "react-router-dom";
import AdComponent from "../../components/AdComponent";

function Result() {
  return (
    <>
      <ResultHeader></ResultHeader>
        <Outlet />
      <AdComponent></AdComponent>
    </>
  );
}

export default Result;
